import { forEachNode, scrollTo } from '../../helpers/dom.helper';

export const BTN_CTA_SELECTOR = '.btn-cta[data-category-url], .btn-cta[data-scroll-to]';

class BtnCTA {

  constructor() {
    this.bindEvents();
  }

  bindEvents(target?): void {
    forEachNode((target || document).querySelectorAll(BTN_CTA_SELECTOR), btn => {
      if (btn.classList.contains('js-cta-modal')) {
        // TODO: we support cost btn for /bsdir/ related pages for now. Should extended or removed later.
        if (window.location.href.indexOf('/bsdir/') !== -1) {

          return;
        }
      }
      btn.addEventListener('click', event => {
        event.stopPropagation();
        event.preventDefault();
        const scrollToClass = btn.dataset.scrollTo;
        const categoryUrl = btn.dataset.categoryUrl;
        const offset = btn.dataset.offset;
        const $overlay = document.querySelector('.js-landing-overlay');
        let scrollToElement;

        if (scrollToClass) {
          scrollToElement = document.querySelector(scrollToClass) || document.body;
        }

        if (scrollToClass && scrollToElement.offsetHeight) {
          if ($overlay) {
            $overlay.classList.add('showed');
          }
          if (scrollToElement) {
            scrollTo(scrollToElement.offsetTop - (Number(offset) || 0));
          }
        } else if (categoryUrl) {
          window.location.href = categoryUrl;
        }
      });
    });
  }

}

export const btnCTA = new BtnCTA();
