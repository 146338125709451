interface ILocalWindow extends Window {
  origin: string;
}

declare const window: ILocalWindow;

/**
 * Retrieve coordinates of given DOM element
 * @return Object
 */
export const getDOMElementCoords = (elem: HTMLElement): { top: number, left: number } => {
  if (!elem) {
    return {top: 0, left: 0};
  }
  const box = elem.getBoundingClientRect();

  const body = document.body;
  const docEl = document.documentElement;

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  const clientTop = docEl.clientTop || body.clientTop || 0;
  const clientLeft = docEl.clientLeft || body.clientLeft || 0;

  const top  = box.top +  scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
};

/**
 * Scroll animation for element
 * @param element HTMLElement
 * @param to number
 * @param duration value
 */
export const scrollToAnimate = (element: HTMLElement, to: number, duration: number): void => {
  if (duration <= 0 || !element) {
    return;
  }
  const difference = to - element.scrollTop;
  const perTick = difference / duration * 2;

  setTimeout(() => {
    element.scrollTop = element.scrollTop + perTick;
    scrollToAnimate(element, to, duration - 2);
  }, 10);
};

export const scrollTo = (to: number, duration: number = 50) => {
  if (duration <= 0) {
    return;
  }
  const difference = to - window.scrollY;
  const perTick = difference / duration * 2;
  setTimeout(() => {
    window.scrollTo(window.scrollX, window.scrollY + perTick);
    scrollTo(to, duration - 2);
  }, 10);
};

// forEach method, could be shipped as part of an Object Literal/Module
export const forEachNode = <T = HTMLElement>(
  nodes: NodeList | NodeListOf<Element> | HTMLCollectionOf<Element> | Array<T>,
  callback: (node: T, id: number) => void, scope?
)  => {
  if (!nodes) {
    return;
  }
  for (let i = 0; i < nodes.length; i++) {
    callback.call(scope, nodes[i], i); // passes back stuff we need
  }
};

export const updateCssPropertyForAllElements = (
  selector: NodeList | HTMLElement | HTMLCollectionOf<Element>,
  propName: string,
  value: string | number
) => {
  if (!selector || !propName || !value) {
    return;
  }
  if (selector instanceof HTMLElement) {
    selector.style[propName] = value;
  } else {
    forEachNode(
      selector,
      item => item.style[propName] = value
    );
  }
};

export const addClassForAllElements = (
  selector: string | NodeList | HTMLElement | HTMLCollectionOf<Element> = '',
  className: string = ''
): void => {
  if (!selector || !className) {
    return;
  }
  if (selector instanceof HTMLElement) {
    selector.classList.add(className);
  } else {
    forEachNode(
      typeof selector === 'string' ? document.querySelectorAll(selector) : selector,
      item => item.classList.add(className)
    );
  }
};

export const removeClassForAllElements = (
  selector: string | NodeList | HTMLElement | HTMLCollectionOf<Element> = '', className: string = ''
): void => {
  if (!selector || !className) {
    return;
  }
  if (selector instanceof HTMLElement) {
    selector.classList.remove(className);
  } else {
    forEachNode(
      typeof selector === 'string' ? document.querySelectorAll(selector) : selector,
      item => item.classList.remove(className)
    );
  }
};

/**
 * getElementHeight - for elements with display:none
 */
export const getElementHeight = (elem: HTMLElement): number => {
  const elStyle = window.getComputedStyle(elem);
  const elDisplay = elStyle.display;
  // const elPosition = elStyle.position;
  const elVisibility = elStyle.visibility;
  const elMaxHeight = elStyle.maxHeight.replace('px', '')
      .replace('%', '');

  // if its not hidden we just return normal height
  if (elDisplay !== 'none' && elMaxHeight !== '0') {
    return elem.offsetHeight;
  }

  // the element is hidden so:
  // making the elem block so we can measure its height but still be hidden
  // elem.style.position = 'absolute';
  elem.style.visibility = 'hidden';
  elem.style.display = 'block';
  elem.style.maxHeight = 'unset';

  const wantedHeight = elem.offsetHeight;

  // reverting to the original values
  elem.style.display = elDisplay;
  // elem.style.position = elPosition;
  elem.style.visibility = elVisibility;
  elem.style.maxHeight = elMaxHeight;

  return wantedHeight;
};

export const getHeightWithoutPadding = (elem: HTMLElement | Element): number => {
  const paddingTop = parseInt(window.getComputedStyle(elem, undefined).paddingTop
    .replace('px', ''), 10);
  const paddingBottom = parseInt(window.getComputedStyle(elem, undefined).paddingBottom
    .replace('px', ''), 10);

  return elem.clientHeight - paddingTop - paddingBottom;
};

export const slideUp = (elem: HTMLElement, callback?: () => void): void => {
  if (!elem) {
    return;
  }
  elem.style.maxHeight = '0';
  elem.style.display = 'none';
  if (typeof callback === 'function') {
    callback();
  }
};

export const slideDown = (elem: HTMLElement, callback?: () => void, duration: number = .5): void =>  {
  if (!elem) {
    return;
  }
  const elMaxHeight = `${getElementHeight(elem)}px`;
  elem.style.display = 'block';
  // elem.style.transition = `max-height ${duration}s ease-in-out`;
  // elem.style.overflowY = 'hidden';
  elem.style.maxHeight = '0';

  // we use setTimeout to modify maxHeight after display is set (in order we have the transition effect)
  setTimeout(() => {
    elem.style.maxHeight = elMaxHeight;
    if (typeof callback === 'function') {
      callback();
    }
  }, 0);
};

export const getParents = (elem: HTMLElement, parentSelector?) => {

  if (parentSelector === undefined) {
    parentSelector = document;
  }

  const parents = [];
  let p = elem.parentNode;

  while (p !== parentSelector) {
    const o = p;
    parents.push(o);
    p = o.parentNode;
  }
  parents.push(parentSelector);

  return parents;
};

export const anyElementHasClass = (elements: Array<HTMLElement>, selector: string | Array<string> = ''): boolean => {
  let has = false;
  elements.forEach(elem => {
    if (typeof selector === 'string' && elem.classList && elem.classList.contains(selector)) {
      has = true;
    } else if (selector instanceof Array) {
      selector.forEach(s => {
        if (elem.classList && elem.classList.contains(s)) {
          has = true;
        }
      });
    }
  });

  return has;
};

export const insertAfter = (newNode, referenceNode) => {
  referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
};

// Finds y value of given object
export const findPos = el => {
  let curtop = 0;
  if (el.offsetParent) {
    do {
      curtop += el.offsetTop;
      // tslint:disable-next-line:no-conditional-assignment
    } while (el = el.offsetParent);

    return curtop;
  }
};

// check if element is in viewport
export const isInViewport = elem => {
  const {top, left, bottom, right} = elem.getBoundingClientRect();

  return (
    top >= 0 &&
    left >= 0 &&
    bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

/**
 * Emit window event
 * @param name String
 * @param parent Boolean - emit event into parent window or not flag
 */
export const emitParentWindowEvent = (name: string, parent: boolean = true): void => {
  const origin = window.origin || window.location.origin;
  if (origin) {
    parent ? window.parent.postMessage(name, origin) : window.postMessage(name, origin);
  }
};
