/**
 * DEPENDENCIES:
 * * https://www.npmjs.com/package/lazysizes
 */
import { btnCTA } from './ui/libs/btnCTA';

(() => {
  function loadTmp(): void {
    const deferSections: Array<HTMLElement> = Array.prototype.slice.call(
      document.querySelectorAll<HTMLElement>('[data-tmp]')
    );
    setTimeout(() => {
      deferSections.forEach((target: HTMLElement) => {
        const name = target.getAttribute('data-tmp');
        if (name) {
          const tmp = document.getElementById(`tmp-${name}`);
          target.innerHTML = tmp && tmp.innerHTML;
          btnCTA.bindEvents(target);
        }
      });
    }, 999);
  }

  function init(): void {
    loadTmp();
  }

  init();
})();
